import { deleteFile } from "helpers/General/files_helper";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, UncontrolledTooltip } from "reactstrap";
import { NotificationMessage, apiErrorrHandler } from "utils";
import { DeleteModal } from "../Modals";
import { withTranslation } from "react-i18next";
import { buttonsTitles } from "common/data";

const DropZonePreviews = ({ selectedFiles, setselectedFiles, typeId, t }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const filterSelectedFiles = (fileName) => {
    const filteredFiles = selectedFiles.filter(
      (file) => file.name !== fileName
    );
    setselectedFiles(filteredFiles);
  };

  // on Delete button Click
  const handleDeleteClick = (file) => {
    if (file?.id) {
      setIsDeleteModal(true);
      setFileToDelete(file?.id);
    } else {
      filterSelectedFiles(file.name);
    }
  };

  // Delete File From DB
  const handleDeleteFile = async () => {
    try {
      await deleteFile(fileToDelete);
      NotificationMessage("Success", "delete");
      const filteredFiles = selectedFiles.filter(
        (file) => file.id !== fileToDelete
      );
      setselectedFiles(filteredFiles);
      setFileToDelete(null);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsDeleteModal(false);
    }
  };

  const download = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => {
        setError(true);
        setFetching(false);
      });
  };
  return (
    <Fragment>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles
          .filter((item) => item?.type?.id === typeId)
          .map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center justify-content-between">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f?.name}
                        src={f?.preview}
                      />
                    </Col>

                    <Col>
                      {f?.id ? (
                        <a
                          href={f?.path}
                          download={true}
                          target="_blank"
                          rel="noreferrer"
                          className="text-muted font-weight-bold"
                        >
                          {f?.name}
                        </a>
                      ) : (
                        <p className="text-muted font-weight-bold mb-0">
                          {f?.name}
                        </p>
                      )}
                      <p className="mb-0">
                        <strong>{f?.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col className="text-end d-flex justify-content-end">
                      {f?.id && (
                        <Fragment>
                          {/* preview */}
                          <a
                            href={f?.path}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-soft-info waves-effect waves-light shadow-sm rounded-circle me-1"
                            id={`view-${i}-${typeId}`}
                          >
                            <i className="mdi mdi-eye font-size-14"></i>
                          </a>
                          <UncontrolledTooltip
                            placement="top"
                            target={`view-${i}-${typeId}`}
                          >
                            {buttonsTitles.preview}
                          </UncontrolledTooltip>
                          <button
                            disabled={fetching}
                            onClick={() => download(f?.path, f?.name)}
                            type="button"
                            className="btn btn-soft-success waves-effect waves-light shadow-sm rounded-circle me-1"
                            id={`download-${i}-${typeId}`}
                          >
                            <i className="mdi mdi-download font-size-14"></i>
                          </button>
                          <UncontrolledTooltip
                            placement="top"
                            target={`download-${i}-${typeId}`}
                          >
                            {buttonsTitles.download}
                          </UncontrolledTooltip>
                        </Fragment>
                      )}

                      {/* Delete */}
                      <button
                        className="btn btn-soft-danger waves-effect waves-light shadow-sm rounded-circle"
                        id={`deleteFile-${i}-${typeId}`}
                        type="button"
                        onClick={() => handleDeleteClick(f)}
                      >
                        <i className="mdi mdi-close font-size-14"></i>
                      </button>
                      <UncontrolledTooltip
                        placement="top"
                        target={`deleteFile-${i}-${typeId}`}
                      >
                        {f.id ? buttonsTitles.delete : buttonsTitles.remove}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </div>
              </Card>
            );
          })}
      </div>
      <DeleteModal
        // isDeleting={saving}
        show={isDeleteModal}
        onDeleteClick={() => {
          handleDeleteFile();
        }}
        onCloseClick={() => setIsDeleteModal(false)}
      />
    </Fragment>
  );
};

export default withTranslation()(DropZonePreviews);
