import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle";
import AutoInvoiceAccordoin from "./AutoInvoiceAccordoin";

const AutoInvoice = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}

          <PageBreadCrumbAndTitle title="Auto Invoice" pageType="Ddd" />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            <Col xs="12">
              {/* Card To Ouptut White Card Around The Table */}
              <Card>
                <CardBody>
                  <AutoInvoiceAccordoin />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AutoInvoice.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AutoInvoice);
