import { request } from "helpers/api_helper";
import * as url from "helpers/url_helper";
/*
Health care Provider Services
*/

// add new health care Provider service

// url
export const addProviderService = (data) =>
  request({
    url: url.HEALTHCAREPROVIDERSERVICES,
    method: "POST",
    data,
  });

// get all health Care Provider Services with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllProviderServices = ({
  providerId,
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: `${url.HEALTHCAREPROVIDER}/${providerId}/services`,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  });

// show health Care Provider Service
//url/{id}

export const showProviderService = (id) =>
  request({
    url: `${url.HEALTHCAREPROVIDERSERVICES}/${id}`,
    method: "GET",
  });

// update healthCare Provider Service
//url/{id}

export const updateProviderService = (data) =>
  request({
    url: `${url.HEALTHCAREPROVIDERSERVICES}/${data.id}`,
    method: "PUT",
    data,
  });

// delete health Care Provider Service
//url/{id}
export const deleteProviderService = (id) =>
  request({
    url: `${url.HEALTHCAREPROVIDERSERVICES}/${id}`,
    method: "DELETE",
  });

// activate healthCare
//url/activate/{id}
export const activateProviderService = (id) =>
  request({
    url: `${url.HEALTHCAREPROVIDERSERVICES}/${id}/activate`,
    method: "POST",
  });

// deactivate healthCare
//url/deactivate/{id}
export const deactivateProviderService = (id) =>
  request({
    url: `${url.HEALTHCAREPROVIDERSERVICES}/${id}/deactivate`,
    method: "POST",
  });

// Batch Upload Provider Services
export const uploadProviderServices = (providerId, data) =>
  request({
    url: `${url.HEALTHCAREPROVIDER}/${providerId}/services/import`,
    method: "POST",
    data,
  });

// popular service
// url
export const popularService = (providerId, data) =>
  request({
    url: `/stakeholders/healthcare-provider/${providerId}/services/popular`,
    method: "POST",
    data,
  });
