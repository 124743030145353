import { buttonsTitles } from "common/data";
import DropZone from "components/TP/Common/DropZone";
import CustomButton from "components/TP/Common/General/CustomButton";
import PreviewPDF from "components/TP/Common/General/PreviewPDF";
import { LoadingModal } from "components/TP/Common/Modals";
import { StickyHeaderTable } from "components/TP/Common/Tables";
import { parseInvoice } from "helpers/General/google_automations_helper";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { apiErrorrHandler, NotificationMessage } from "utils";

const AutoInvoiceAccordoin = () => {
  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);

  // useEffect(() => {
  //   if (invoiceFiles.length === 0) setIsPreviewMode(false);
  // }, [invoiceFiles.length]);
  // First Endpoint (upload the file)
  const handleUploadPDF = async () => {
    setIsSaving(true);

    const formData = new FormData();
    formData.append("document", invoiceFiles[0]);
    formData.append("healthcare_provider_id", 2);
    try {
      const res = await parseInvoice(formData);
      NotificationMessage("success", "File Uploaded Successfully");

      setPreviewFile(invoiceFiles[0]);
      setIsPreviewMode(true);
      setPdfData(
        res.item
          ? res.item
          : {
              services: [],
              not_found_services: [],
            }
      );

      setInvoiceFiles([]);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col md={4}>
          <DropZone
            single={true}
            isVertical={true}
            inCludeCloudIcon={true}
            label="Invoice File"
            isRequired={true}
            selectedFiles={invoiceFiles}
            setselectedFiles={setInvoiceFiles}
          />
        </Col>
      </Row>

      <div className="d-flex align-items-center gap-2 mt-2">
        <CustomButton
          disabled={isSaving || invoiceFiles.length === 0 ? true : false}
          isSaving={isSaving}
          className="btn btn-primary"
          type="button"
          onClick={() => {
            handleUploadPDF();
          }}
          title={buttonsTitles.upload}
        />
      </div>

      {isPreviewMode && (
        <Row className="mt-3">
          <Col md={6}>
            <PreviewPDF file={previewFile} isLoading={isSaving} />
          </Col>
          <Col md={6}>
            {/* Services */}
            <div className="mb-3">
              <h4 className="mb-2">Services</h4>
              <StickyHeaderTable
                noData={pdfData?.services?.length === 0 ? true : false}
                isLoading={false}
                columns={[
                  "Id",
                  "Description",
                  "Provider Service Ref",
                  "Date",
                  "Qty",
                  "Unit Price",
                  "Total Price",
                ]}
                height="300px"
              >
                {pdfData?.services?.length > 0 ? (
                  <Fragment>
                    {pdfData?.services?.map((service, index) => (
                      <tr key={`${service.service_id}=${index}`}>
                        <td>{service.service_id}</td>
                        <td>{service.description}</td>
                        <td>{service.provider_service_reference}</td>
                        <td>{service.date}</td>
                        <td>{service.quantity}</td>
                        <td>{service.unit_price}</td>
                        <td>{service.total_price}</td>
                      </tr>
                    ))}
                  </Fragment>
                ) : null}
              </StickyHeaderTable>
            </div>
            {/* not found services */}
            <div>
              <h4 className="mb-2">Not Found Services</h4>
              <StickyHeaderTable
                noData={
                  pdfData?.not_found_services?.length === 0 ? true : false
                }
                isLoading={false}
                columns={[
                  "Id",
                  "Description",
                  "Provider Service Ref",
                  "Date",
                  "Qty",
                  "Unit Price",
                  "Total Price",
                ]}
                height="300px"
              >
                {pdfData?.not_found_services?.length > 0 ? (
                  <Fragment>
                    {pdfData?.not_found_services?.map((service, index) => (
                      <tr key={`${service.service_id}=${index}`}>
                        <td>{service.service_id}</td>
                        <td>{service.description}</td>
                        <td>{service.provider_service_reference}</td>
                        <td>{service.date}</td>
                        <td>{service.quantity}</td>
                        <td>{service.unit_price}</td>
                        <td>{service.total_price}</td>
                      </tr>
                    ))}
                  </Fragment>
                ) : null}
              </StickyHeaderTable>
            </div>
          </Col>
        </Row>
      )}

      <LoadingModal isOpen={isSaving} />
    </Fragment>
  );
};

export default AutoInvoiceAccordoin;
