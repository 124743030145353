import React, { Fragment, useMemo, useState } from "react";
//Import Breadcrumb
import PDFViewer from "pdf-viewer-reactjs";
import MultiSkeleton from "components/TP/Common/MultiSkeleton";
import { ModalSpinner } from "components/TP/Common/Forms";

import "./previewPDF.css";
import { buttonsTitles } from "common/data";

const PreviewPDF = ({ fileURL, file, isLoading }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const doc = useMemo(
    () => ({ url: URL.createObjectURL(file) }),
    [JSON.stringify(file)]
  );

  return (
    <Fragment>
      <h5 className={`my-1 text-capitalize`}>{buttonsTitles.preview}</h5>
      <div className="border border-gray-500 rounded-2">
        {isLoading ? (
          <ModalSpinner />
        ) : (
          <Fragment>
            {page === 1 && (
              <PDFViewer
                document={doc}
                loader={<MultiSkeleton length={35} />}
                hideRotation
                hideZoom
                page={1}
                css="m-0 p-0"
                canvasCss="h-auto canvasWidth"
                externalInput
                getMaxPageCount={(pageCount) => {
                  if (totalPages === 0) setTotalPages(pageCount);
                }}
              />
            )}
            {page > 1 &&
              Array.from({ length: totalPages - 1 }).map((_, i) => (
                <Fragment key={i}>
                  {i + 2 === page ? (
                    <PDFViewer
                      document={doc}
                      loader={<MultiSkeleton length={35} />}
                      hideRotation
                      hideZoom
                      page={i + 2}
                      css="m-0 p-0"
                      canvasCss="h-auto canvasWidth"
                      externalInput
                    />
                  ) : null}
                </Fragment>
              ))}
            <div className="d-flex align-items-center justify-content-center gap-1 my-2">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setPage((prev) => prev - 1)}
                disabled={page === 1 ? true : false}
              >
                Prev
              </button>
              <span>{`Page ${page} / ${totalPages}`}</span>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setPage((prev) => prev + 1)}
                disabled={page === totalPages ? true : false}
              >
                Next
              </button>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default PreviewPDF;
