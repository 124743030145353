import React, { Fragment, useState } from "react"
import { useDroppable } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"

import SortableItem from "./SortableItem"
import CustomButton from "components/TP/Common/General/CustomButton"
import ServicesTableModal from "./ServicesTableModal"
import { flatten } from "lodash"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"

const SortingContainer = ({
  id,
  items,
  allItems,
  setItems,
  name,
  provider,
  isLoading,
}) => {
  const { setNodeRef } = useDroppable({
    id,
  })

  const [isTable, setIsTable] = useState(false)

  return (
    <Fragment>
      <SortableContext
        id={id}
        items={items}
        strategy={verticalListSortingStrategy}
      >
        <div
          ref={setNodeRef}
          className="bg-gray-200 p-4 rounded-3"
          style={{ flex: "1" }}
        >
          {isLoading ? (
            <MultiSkeleton length={20} />
          ) : (
            <Fragment>
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="font-size-14 fw-bold">
                  {name} {""} {items.length ? `(${items.length})` : ""}
                </h2>
                {id === "services" && (
                  <CustomButton
                    disabledTooltip={"Select Provider First"}
                    title="Add Service"
                    disabled={!provider ? true : false}
                    className="btn btn-success"
                    onClick={() => {
                      setIsTable(true)
                    }}
                  />
                )}
              </div>
              {items.map(service => (
                <SortableItem key={service?.id} service={service} />
              ))}
            </Fragment>
          )}
        </div>
      </SortableContext>

      {isTable && (
        <ServicesTableModal
          show={isTable}
          onCloseClick={() => {
            setIsTable(false)
          }}
          providerId={provider?.id}
          setItems={setItems}
          itemsIds={flatten(Object.values(allItems)).map(item => item.id)}
        />
      )}
    </Fragment>
  )
}

export default SortingContainer
