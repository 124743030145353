import React, { useMemo } from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

export function Item({ service, isDragging }) {
  const { serviceNameCode } = useMemo(() => {
    return {
      serviceNameCode: `${service?.provider_service_reference}/${service?.name}`,
    }
  }, [JSON.stringify(service)])
  return (
    <div
      className={`rounded-3 my-3 p-3 bg-white ${
        isDragging ? "opacity-25 border border-3 border-primary" : "opacity-100"
      }`}
    >
      {/* <h3 className="font-size-14">{service.name}</h3> */}
      <h3
        className="font-size-14"
        data-toggle="tooltip"
        title={serviceNameCode}
      >
        {serviceNameCode.slice(0, 100)}
        {serviceNameCode.length > 100 ? "..." : ""}
      </h3>
    </div>
  )
}

const SortableItem = ({ service }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: service.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item id={service.id} service={service} isDragging={isDragging} />
    </div>
  )
}

export default SortableItem
