import React, { useEffect, useState } from "react";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import SortingContainer from "./SortingContainer";
import { Item } from "./SortableItem";
import { Col, Container, Form, Row } from "reactstrap";
import { FormControll } from "components/TP/Common/Forms";
import { buttonsTitles } from "common/data";
import { flatten } from "lodash";
import { NotificationMessage, apiErrorrHandler, decryptText } from "utils";
import {
  getAllProviderServices,
  popularService,
} from "helpers/Stakeholders/healthCare/provider_services_helper";

const defaultAnnouncements = {
  onDragStart(id) {
    console.log(`Picked up draggable item ${id}.`);
  },
  onDragOver(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was moved over droppable area ${overId}.`
      );
      return;
    }

    console.log(`Draggable item ${id} is no longer over a droppable area.`);
  },
  onDragEnd(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was dropped over droppable area ${overId}`
      );
      return;
    }

    console.log(`Draggable item ${id} was dropped.`);
  },
  onDragCancel(id) {
    console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
  },
};

const Test = () => {
  // main payer
  const mainProvider = JSON.parse(
    decryptText(localStorage.getItem("mainProvider"))
  );

  const [items, setItems] = useState({
    section1: [],
    section2: [],
    section3: [],
    services: [],
  });
  const [activeId, setActiveId] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handlePopularService = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const flattenServices = [
      ...items.section1,
      ...items.section2,
      ...items.section3,
    ].map((service, index) => ({
      display_order: index + 1,
      service_id: service?.service?.id,
    }));
    try {
      await popularService(mainProvider?.id, flattenServices);
      NotificationMessage("success", "add");
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const getPopularServices = async (providerId) => {
    try {
      setIsFetching(true);
      const servicesRes = await getAllProviderServices({
        providerId,
        page: 1,
        per_page: 1000,
        is_popular: 1,
        sort_by: "display_order",
        sort_type: "asc",
      });
      setItems({
        section1: servicesRes.items?.slice(0, 50),
        section2: servicesRes.items?.slice(50, 100),
        section3: servicesRes.items?.slice(100, 150),
        services: [],
      });
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    }
  };

  useEffect(() => {
    if (mainProvider) {
      getPopularServices(mainProvider?.id);
    }
  }, [JSON.stringify(mainProvider)]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* provider dropdown */}

          <Form onSubmit={handlePopularService}>
            <Row>
              <Col md={4}>
                <FormControll
                  id="400"
                  name="healthcare_provider_id"
                  value={mainProvider?.first_name}
                  label="Healthcare Provider"
                  onChange={() => {}}
                  onBlur={() => {}}
                  invalid={false}
                  error={""}
                  wrapperClassNames="mb-3"
                  readOnly={true}
                />
              </Col>
              <Col md={2}>
                <button
                  type="submit"
                  className="btn btn-primary mt-4"
                  disabled={isLoading || !mainProvider}
                >
                  {buttonsTitles.save}
                </button>
              </Col>
            </Row>
          </Form>

          {/* Boxes */}
          <div className="d-flex gap-2 flex-wrap">
            <DndContext
              announcements={defaultAnnouncements}
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
            >
              <SortingContainer
                id="section1"
                items={items.section1}
                name="Section #1"
                isLoading={isFetching}
              />
              <SortingContainer
                id="section2"
                items={items.section2}
                name="Section #2"
                isLoading={isFetching}
              />
              <SortingContainer
                id="section3"
                items={items.section3}
                name="Section #3"
                isLoading={isFetching}
              />
              <SortingContainer
                id="services"
                items={items.services}
                allItems={items}
                name="Services List"
                provider={mainProvider}
                setItems={setItems}
              />
              <DragOverlay>
                {activeId ? (
                  <Item
                    service={flatten(Object.values(items)).find(
                      (item) => item.id === activeId
                    )}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );

  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) =>
      items[key].find((item) => item.id === id)
    );
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];
      // Find the indexes for the items
      const activeIndex = activeItems.map((item) => item.id).indexOf(id);
      const overIndex = overItems.map((item) => item.id).indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the section1 droppable of a container
        newIndex = overItems.length + 1;
      } else {
        // const isBelowLastItem =
        //   over &&
        //   overIndex === overItems.length - 1 &&
        //   draggingRect.offsetTop > over.rect.offsetTop + over.rect.height
        const isBelowLastItem = true;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item.id !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer]
      .map((item) => item.id)
      .indexOf(active.id);
    const overIndex = items[overContainer]
      .map((item) => item.id)
      .indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(
          items[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    setActiveId(null);
  }
};

export default Test;
