import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Modal } from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"
import { TableContainer } from "components/Common"
import { NotificationMessage, apiErrorrHandler, fieldFinder } from "utils"
import { useDispatch, useSelector } from "react-redux"
import { healthCareProviderServiceID } from "utils/modulesIds"
import { buttonsTitles } from "common/data"

import { getSubmodules } from "store/actions"
import { getAllProviderServices } from "helpers/Stakeholders/healthCare/provider_services_helper"

const ServicesTableModal = ({
  show,
  onCloseClick,
  providerId,
  onSaveClick,
  setItems,
  itemsIds,
  t,
}) => {
  // states
  const [services, setServices] = useState([])
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const [servicesIds, setServicesIds] = useState([])
  // const [columns,setColumns] = useState([])
  const dispatch = useDispatch()

  const {
    fields,
    moduleName,
    loading: isLoading,
  } = useSelector(state => ({
    fields: state.Module?.fields,
    moduleName: state.Module?.name,
    loading: state.Module?.loading,
  }))

  //Header of the Table
  const columns = useMemo(
    () =>
      fields?.length > 0
        ? [
            {
              Header: fieldFinder(fields, 487).label_name,
              accessor: fieldFinder(fields, 487).index_name,
              name: fieldFinder(fields, 487).index_name,
            },
            {
              Header: fieldFinder(fields, 161).label_name,
              accessor: fieldFinder(fields, 161).index_name,
              name: fieldFinder(fields, 161).index_name,
            },
            {
              Header: fieldFinder(fields, 162).label_name,
              accessor: fieldFinder(fields, 162).index_name,
              name: fieldFinder(fields, 162).index_name,
            },

            {
              Header: fieldFinder(fields, 164).label_name,
              accessor: fieldFinder(fields, 164).index_name,
              name: fieldFinder(fields, 164).index_name,
            },
            {
              Header: fieldFinder(fields, 165).label_name,
              accessor: fieldFinder(fields, 165).index_name,
              name: fieldFinder(fields, 165).index_name,
            },
          ]
        : [],
    [fields]
  )

  // Get All Services for that healthcare Provider
  const getProviderServices = async ({
    page,
    per_page,
    sort_type,
    sort_by,
    // search_value,
    search_keys,
    search_values,
  }) => {
    try {
      setLoading(true)
      const servicesRes = await getAllProviderServices({
        providerId: providerId,
        page,
        per_page,
        sort_type,
        sort_by,
        // search_value,
        search_keys,
        search_values,
      })
      setServices(
        servicesRes.items.map(item => ({
          ...item,
          disableCheckbox: itemsIds.includes(item.id),
        }))
      )
      setPagination({
        size: servicesRes.size,
        page: servicesRes.page,
        per_page: servicesRes.per_page,
        total_pages: servicesRes.total_pages,
        total: servicesRes.total_size,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    dispatch(getSubmodules(healthCareProviderServiceID))
  }, [])

  //   After select and save
  const handleSave = () => {
    const selectedServices = services.filter(item =>
      servicesIds.includes(item.id)
    )
    setItems(prev => {
      return { ...prev, services: selectedServices }
    })
    onCloseClick()
  }
  return (
    <Modal
      size="xl"
      isOpen={show}
      toggle={onCloseClick}
      backdrop="static"
      className="modal-fullscreen"
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          {moduleName}
        </h5>

        {/* close button on the top right section */}
        <button
          aria-label="Close Modal"
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <TableContainer
          columns={columns}
          data={services}
          customPageSizeOptions={20}
          pagination={pagination}
          getData={getProviderServices}
          loading={loading}
          removeActionsButtons={true}
          removeActionsColumns={true}
          includeCheckBoxes={true}
          selectAllCheckBox={true}
          idsArray={servicesIds}
          setIdsArray={setServicesIds}
          fullColumns={columns}
          sheetName="Healthcare Provider Services"
        />
      </div>

      <div className="modal-footer d-flex gap-1">
        <button
          aria-label="Save Services"
          type="button"
          className="btn btn-primary"
          onClick={handleSave}
        >
          {buttonsTitles.save}
        </button>
        <button
          aria-label="Close Modal"
          type="button"
          className="btn btn-danger"
          onClick={onCloseClick}
        >
          {buttonsTitles.close}
        </button>
      </div>
    </Modal>
  )
}

ServicesTableModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  providerId: PropTypes.number,
}

export default withTranslation()(ServicesTableModal)
