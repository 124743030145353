import * as url from "helpers/url_helper"
import { request } from "helpers/api_helper"

// parseInvoice
//url
export const parseInvoice = data =>
  request({
    url: url.INVOICEPARSE,
    method: "POST",
    data,
  })
